import { useEffect, useState, useContext } from 'react';
import { Row, Col, Steps } from 'antd';
import { MyContext } from '../../../../../App';

export function CheckoutSteps() {
    const { UserData } = useContext(MyContext);

    // Initialize default step statuses
    const defaultStepStatus = {
        quote: "wait",  // Changed from "process" to "wait"
        setup: "wait",
        review: "wait",
        pay: "wait",
        complete: "wait"
    };

    const [stepState, setStepStatus] = useState(defaultStepStatus);
    const [readyToRender, setReadyToRender] = useState(false); // New state to control rendering

    // Update stepState only if the new state differs from the current state
    const updateState = (newState) => {
        setStepStatus(prevState => {
            // Check if the new state is different from the current state
            const hasChanged = Object.keys(newState).some(key => newState[key] !== prevState[key]);
            if (hasChanged) {
                return { ...prevState, ...newState };
            }
            return prevState;
        });
    };

    useEffect(() => {
        // Determine the current step status based on UserData
        const getStatusBasedOnUserData = () => {
            if (UserData?.policy?.user?.paid) {
                return {
                    quote: "finish",
                    setup: "finish",
                    review: "finish",
                    pay: "finish",
                    complete: "finish"
                };
            } else if (UserData?.policy?.user?.tandc) {
                return {
                    quote: "finish",
                    setup: "finish",
                    review: "finish",
                    pay: "process",
                    complete: "wait"
                };
            } else if (UserData?.policy?.user?.sof) {
                return {
                    quote: "finish",
                    setup: "finish",
                    review: "process",
                    pay: "wait",
                    complete: "wait"
                };
            } else if (UserData?.policy?.plan) {
                return {
                    quote: "finish",
                    setup: "process",
                    review: "wait",
                    pay: "wait",
                    complete: "wait"
                };
            } else {
                return defaultStepStatus;
            }
        };

        const newState = getStatusBasedOnUserData();
        updateState(newState);
        setReadyToRender(true); // Allow rendering after state is set

    }, [UserData]); // Re-run when UserData changes

    // Conditionally render the Steps component only when readyToRender is true
    return readyToRender && (
        <Row justify="center">
            <Col span={18}>
                <Steps
                    size="small"
                    items={[
                        { title: 'Quote', status: stepState.quote },
                        { title: 'Setup', status: stepState.setup },
                        { title: 'Review', status: stepState.review },
                        { title: 'Pay', status: stepState.pay },
                        { title: 'Complete', status: stepState.complete },
                    ]}
                />
            </Col>
        </Row>
    );
}
