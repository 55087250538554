import React, { useState } from "react"
import { Row, Col, Table } from "antd"
import { tableConfig, generateColumns } from "./pricingTableConfig"
import styles from "./PricingTable.module.css"
import { PlanSummary } from "../PlanSummary"

export function PricingTable({ customBottom = "" }) {
      return (
            <div className={`section section-dark ${customBottom}`}>

                  <PlanSummary />

                  <Row justify="center" style={{ marginBottom: 100 }}>
                        <Col xs={24} sm={16}>
                              <p className="lead" style={{ marginBottom: 50 }}>All of our tool insurance plans include/exclude ..</p>
                              {tableConfig.map((table, index) => (
                                    <PricingTableSection
                                          key={index}
                                          title={table.title}
                                          data={table.data}
                                    />
                              ))}
                        </Col>
                  </Row>

                  <PlanSummary />

            </div>
      )
}

function PricingTableSection({ title, data }) {
      const [ , setTooltipContent ] = useState("")
      const [ visibleTooltipKey, setVisibleTooltipKey ] = useState(null)

      const handleTooltipClick = (content, key) => {
            setTooltipContent(content)
            setVisibleTooltipKey(key)
      }

      const columns = generateColumns(handleTooltipClick, visibleTooltipKey)

      return (
            <Row align="middle" style={{ marginBottom: "20px" }}>
                  <Col xs={24} sm={24}>
                        <h2 className={styles.PricingTableHeader}>{title}</h2>
                        <Table
                              className={styles.darkTable}
                              columns={columns}
                              dataSource={data}
                              pagination={false}
                        />
                  </Col>
            </Row>
      )
}
