import React from 'react'

export default function Wave({ primary = "#F7B829", after = "#2B2B2B" }) {
      return (
            <div className="wave-bottom">
                  <svg
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 3062.5 201.1"
                        style={{ enableBackground: 'new 0 0 3062.5 201.1', marginBottom: -5 }}
                        fill={after}
                  >
                        <defs>
                              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="232.1396" y1="-5129.9985" x2="232.8467" y2="-5129.2915">
                                    <stop offset="0" style={{ stopColor: after }} />
                                    <stop offset="0.1738" style={{ stopColor: after }} />
                                    <stop offset="0.352" style={{ stopColor: after }} />
                                    <stop offset="0.5323" style={{ stopColor: after }} />
                                    <stop offset="0.7139" style={{ stopColor: after }} />
                                    <stop offset="0.8949" style={{ stopColor: after }} />
                                    <stop offset="1" style={{ stopColor: after }} />
                              </linearGradient>
                              <linearGradient id="SVGID_00000068647501100131739910000016477870572474433960_" gradientUnits="userSpaceOnUse" x1="0" y1="100.5193" x2="3062.49" y2="100.5193">
                                    <stop offset="0" style={{ stopColor: primary }} />
                                    <stop offset="0.25" style={{ stopColor: primary, stopOpacity: 0.75 }} />
                                    <stop offset="0.50" style={{ stopColor: primary, stopOpacity: 0.50 }} />
                                    <stop offset="0.75" style={{ stopColor: primary, stopOpacity: 0.25 }} />
                                    <stop offset="1" style={{ stopColor: primary, stopOpacity: 0.1 }} />
                              </linearGradient>
                        </defs>
                        <path
                              style={{ fill: 'url(#SVGID_00000068647501100131739910000016477870572474433960_)' }}
                              d="M3062.5,26.7v147.4c0,0-361.9-81.6-802-81.6c-276.9,0-671,44.8-761.8,57.4c-90.8,12.6-519.3,56.3-725,50.6C567,195.9,244.1,186.1,0,154.8V89.2c0,0,240.8,73.5,780.6,84.8c128.5,1.7,185.1,14.1,863.3-84.8C2242.5,1.9,2683.7-26.2,3062.5,26.7z"
                        />
                        <path
                              style={{ fill: after }} // Updated to inline style
                              d="M2260.5,92.5c-276.9,0-671,44.8-761.8,57.4c-90.8,12.6-519.3,56.3-725,50.6C567,195.9,244.1,186.1,0,154.8v46.3l3062.5-0.2v-26.7v-0.1C3062.5,174.1,2700.6,92.5,2260.5,92.5z"
                        />
                  </svg>
            </div>
      )
}
