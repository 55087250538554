import { useContext } from "react"
import { MyContext } from "../../../../App"
import { Row, Col, Button, Form, Space } from "antd"
import { useNavigate } from "react-router-dom"

import { AboutYou } from "./AboutYou"
import { Postcode } from "./Postcode"
import { AboutBusiness } from "./AboutBusiness"
import { AboutVehicleEquipment } from "./AboutVehicleEquipment"

export function S1Form() {

      const { setUserData } = useContext(MyContext)

      const [form] = Form.useForm()

      const navigate = useNavigate()

      const onFinish = (data) => {
            console.log('Step One Complete')
            console.log(data)
            setUserData({
                  policy: {
                        user: {
                              title: 'Mr',
                              firstName: 'Oliver',
                              lastName: 'Kingston',
                              email: 'ojkingston@gmail.com'
                        }
                  }
            })
            navigate("/setup/two/")
      }

      const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo)
      }

      return (

            <div className={`section section-dark`} style={{ paddingTop: 50 }}>
                  <Row justify="center">
                        <Col xs={23} sm={18}>
                              <Form
                                    form={form}
                                    name="S1Form"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    layout="vertical"
                              >

                                    <AboutBusiness form={form} />

                                    <AboutVehicleEquipment form={form} />

                                    <AboutYou form={form} />

                                    <Postcode form={form} />

                                    <Space>
                                          <Button onClick={() => navigate(-1)} style={{ marginBottom: 125 }} type="default" htmlType="submit" size="large">
                                                Back
                                          </Button>
                                          <Button style={{ marginBottom: 125 }} type="primary" htmlType="submit" size="large">
                                                Next
                                          </Button>
                                    </Space>
                              </Form>
                        </Col>
                  </Row>
            </div>

      )

}