import { useContext } from "react"
import { MyContext } from "../../../App"
import { Row, Col, Button, Space } from "antd"
import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { Footer } from "../../common/Footer"
import { useNavigate } from "react-router-dom"

export default function Pay() {

    const { setUserData } = useContext(MyContext)

    const navigate = useNavigate()

    const startPayment = () => {
        setUserData({
            policy: {
                user: {
                    paid: true
                }
            }
        })
        navigate("/complete")
    }

    return (

        <>

            <Header />

            <Hero
                backgroundImage="carpenter"
                mainHeader="Payment options"
                content="Check out the payment options below and decide which suits you best."
            />

            <div className={`section section-dark`}>
                <Row justify="center">
                    <Col span={18}>
                        <div style={{ marginTop: 50, marginBottom: 125 }}>
                            <Space >
                                <Button onClick={() => navigate(-1)} type="default" htmlType="submit" size="large">
                                    Back
                                </Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => { startPayment() }}
                                >
                                    Click to Pay
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </div>

            <Footer />

        </>

    )
}