import { useEffect, useRef } from "react"

export const useDevice = (setUserData) => {

      const deviceDataRef = useRef({}) // To hold the last known state

      useEffect(() => {

            const connectionType = navigator.connection && 'type' in navigator.connection ? navigator.connection.type : 'unknown'

            const updateDeviceData = async () => {

                  const newDeviceObject = {
                        type: window.innerWidth < 575 ? 'small' : window.innerWidth < 992 ? 'medium' : 'large',
                        isVisible: document.visibilityState === 'visible',
                        useragent: navigator.userAgent,
                        language: navigator.language,
                        connection: {
                              online: navigator.onLine,
                              type: connectionType,
                              effectiveType: navigator.connection ? navigator.connection.effectiveType : undefined,
                              downlink: navigator.connection ? navigator.connection.downlink : undefined,
                              rtt: navigator.connection ? navigator.connection.rtt : undefined
                        },
                        viewport: { width: window.innerWidth, height: window.innerHeight }
                  }

                  // Check if the device data has changed
                  if (JSON.stringify(newDeviceObject) !== JSON.stringify(deviceDataRef.current)) {
                        deviceDataRef.current = newDeviceObject // Update the ref with the new state
                        setUserData({ device: newDeviceObject }) // Update the AppData if there is a change
                  }
            }

            const handleResize = () => {
                  updateDeviceData()
            }

            const handleVisibilityChange = () => {
                  updateDeviceData()
            }

            const handleConnectionChange = () => {
                  updateDeviceData()
            }

            window.addEventListener('online', updateDeviceData)
            window.addEventListener('offline', updateDeviceData)

            window.addEventListener('resize', handleResize)
            document.addEventListener('visibilitychange', handleVisibilityChange)
            navigator.connection?.addEventListener('change', handleConnectionChange)

            // Initialize device data and event listeners
            updateDeviceData()

            // Cleanup function
            return () => {
                  // ... Cleanup event listeners ...
                  window.removeEventListener('online', updateDeviceData)
                  window.removeEventListener('offline', updateDeviceData)
            }
      }, [setUserData])
}