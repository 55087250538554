import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Form, Input, Dropdown, Space, Button, Spin } from "antd"
import IconWithText from "../../../../ui/IconWithText"
import { IoSearch as SearchIcon } from "react-icons/io5"
import { handlePostcodeValidation } from "./helper"

export function Postcode({ form }) {
      const [ addresses, setAddresses ] = useState([])
      const [ dropdownVisible, setDropdownVisible ] = useState(false)
      const [ selectedAddress, setSelectedAddress ] = useState(null)
      const [ loading, setLoading ] = useState(false) // Add loading state
      const [ validationError, setValidationError ] = useState(null) // State to handle validation error

      const inputRef = useRef(null) // Reference for the input field

      const handleMenuClick = (address) => {
            setSelectedAddress(address)
            setDropdownVisible(false)
      }

      const menuItems = addresses.map((address, index) => ({
            key: index,
            label: (
                  <div onClick={() => handleMenuClick(address)}>
                        {`${address.address1}${address.address2 ? `, ${address.address2}` : ''}${address.address3 ? `, ${address.address3}` : ''}, ${address.town}, ${address.postcode}`}
                  </div>
            ),
      }))

      useEffect(() => {
            console.log("Selected Address:", selectedAddress)
            setDropdownVisible(false)
      }, [ selectedAddress ])

      const onPostcodeInput = (e) => {
            // Clear any previous results
            setAddresses([])
      }

      const handleChangeAddress = () => {
            setAddresses([])
            setSelectedAddress(null) // Reset the selected address
            form.resetFields([ 'search' ]) // Clear the input field

            // Defer focus to ensure the input field is rendered
            setTimeout(() => {
                  if (inputRef.current) {
                        inputRef.current.focus()
                  }
            }, 0)
      }

      const validatePostcode = async (rule, value) => {
            try {
                  // Call the validation function from the helper
                  await handlePostcodeValidation(
                        rule,
                        value,
                        form,
                        setAddresses,
                        setDropdownVisible,
                        setLoading
                  )
                  // Clear any previous validation error
                  setValidationError(null)
            } catch (error) {
                  // If there's an error, set the validation error message
                  setValidationError(error)
                  // Remove the error message after 3 seconds
                  setTimeout(() => setValidationError(null), 3000)
                  // Reject the promise to trigger Ant Design's validation mechanism
                  return Promise.reject(error)
            }
      }

      return (
            <div className="darkFormSection">

                  <Row>
                        <Col>
                              <h2>YOUR BILLING ADDRESS</h2>
                        </Col>
                  </Row>

                  {selectedAddress === null ? (
                        <div id="addressLookupWrapper">
                              <Row gutter={32}>
                                    <Col xs={24} sm={8}>
                                          <Form.Item
                                                name="search"
                                                label={
                                                      <IconWithText
                                                            style={{ fontSize: 9 }}
                                                            icon={<SearchIcon />}
                                                            iconLocation="right"
                                                            text="Postcode Search"
                                                      />
                                                }
                                                validateStatus={validationError ? 'error' : ''}
                                                help={validationError || ''}
                                                rules={[
                                                      {
                                                            validator: validatePostcode,
                                                      },
                                                ]}
                                          >
                                                <Input
                                                      ref={inputRef} // Attach the ref to the input
                                                      onChange={(e) => onPostcodeInput(e)}
                                                      suffix={loading ? <Spin style={{ fontSize: 8, color: "var(--yellow) !important" }} /> : null} // Show spinner when loading
                                                />
                                          </Form.Item>
                                    </Col>
                              </Row>

                              {addresses.length > 0 && (
                                    <Dropdown
                                          menu={{ items: menuItems }}
                                          open={dropdownVisible}
                                          placement="bottomLeft"
                                          onOpenChange={(visible) => setDropdownVisible(visible)}
                                          // overlayClassName="yellowDropdown"
                                          trigger={[ 'click' ]}
                                    >
                                          <div
                                                onClick={(e) => e.preventDefault()}
                                                style={{
                                                      color: 'darkgrey',
                                                      background: 'none',
                                                      border: 'none',
                                                      padding: 0,
                                                      cursor: 'pointer',
                                                }}
                                          >
                                                {/* <Space>
                                                      <Button type="primary">Found {addresses.length} addresses</Button>
                                                </Space> */}
                                          </div>
                                    </Dropdown>
                              )}
                        </div>
                  ) : (
                        <div id="addressSelectedWrapper">
                              <div style={{ color: "white", marginBottom: '10px', marginTop: "-25px" }}>
                                    <p>{`${selectedAddress.address1}${selectedAddress.address2 ? `, ${selectedAddress.address2}` : ''}${selectedAddress.address3 ? `, ${selectedAddress.address3}` : ''}, ${selectedAddress.town}, ${selectedAddress.postcode}`}</p>
                              </div>
                              <Button type="default" size="small" onClick={handleChangeAddress}>
                                    Change Address
                              </Button>
                        </div>
                  )}
            </div>
      )
}
