import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { formatCurrency } from "../../../../_utils/helper"
import { Row, Col, Divider } from "antd"
import { MyContext } from "../../../../App"
import styles from "./PlanSummary.module.css"
import { RiShieldFlashFill as EssentialPlanIcon, RiShieldCrossFill as PlusPlanIcon } from "react-icons/ri"
import { GoChevronRight as SelectIcon } from "react-icons/go"
import { Loading } from "../../../ui/Loading"


export function PlanSummary() {

      const navigate = useNavigate()

      const { AppData, UserData, setUserData } = useContext(MyContext)

      const essentialPrice = UserData.policy.payment.type === "Y"
            ? formatCurrency(UserData.policy.price.essentials)
            : formatCurrency(parseFloat(UserData.policy.price.essentials) / 12)

      const plusPrice = UserData.policy.payment.type === "Y"
            ? formatCurrency(UserData.policy.price.plus)
            : formatCurrency(parseFloat(UserData.policy.price.plus) / 12)

      const selectPlan = (plan) => {
            console.log(`Plan Selected:`, plan)
            setUserData({
                  policy: {
                        plan
                  }
            })
            navigate("/setup/one")
      }

      return (

            <div className={styles.PlanSummaryWrapper}>
                  <Row justify="center">
                        <Col xs={24} sm={18}>
                              <Row justify="center" gutter={32}>
                                    <Col xs={24} sm={12}>
                                          <div onClick={() => selectPlan('essentials')} className={`${styles.PlanSummaryCard} ${styles.PlanSummaryCardEssential}`}>
                                                <Row align="middle">
                                                      <Col span={12}>
                                                            <div className={styles.PlanSummaryLabel}><EssentialPlanIcon />ESSENTIALS</div>
                                                            <Divider className={styles.PlanSummaryDivider} />
                                                            {AppData.loading
                                                                  ? <div className={styles.PlanSummaryLoading}><Loading type="bars" /></div>
                                                                  : <div className={styles.PlanSummaryPrice}>{essentialPrice}
                                                                        <span className={styles.PlanSummaryPriceSuffix}>/ {UserData.policy.payment.type === "Y" ? "year" : "month"}</span>
                                                                  </div>
                                                            }
                                                      </Col>
                                                      <Col span={1} offset={7} style={{ fontSize: 104, textAlign: "right", color: "rgba(255,255,255,0.05)" }}>
                                                            <SelectIcon style={{ verticalAlign: -10 }} />
                                                      </Col>
                                                </Row>
                                          </div>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                          <div onClick={() => selectPlan('plus')} className={`${styles.PlanSummaryCard} ${styles.PlanSummaryCardPlus}`}>
                                                <Row align="middle">
                                                      <Col span={12}>
                                                            <div className={styles.PlanSummaryLabel}><PlusPlanIcon />PLUS</div>
                                                            <Divider className={styles.PlanSummaryDivider} />
                                                            {AppData.loading
                                                                  ? <div className={styles.PlanSummaryLoading}><Loading type="bars" /></div>
                                                                  : <div className={styles.PlanSummaryPrice}>{plusPrice}
                                                                        <span className={styles.PlanSummaryPriceSuffix}>/ {UserData.policy.payment.type === "Y" ? "year" : "month"}</span>
                                                                  </div>
                                                            }
                                                      </Col>
                                                      <Col span={1} offset={7} style={{ fontSize: 104, textAlign: "right", color: "rgba(0,0,0,0.05)" }}>
                                                            <SelectIcon style={{ verticalAlign: -10 }} />
                                                      </Col>
                                                </Row>
                                          </div>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col xs={24}>
                                          <p style={{ fontSize: "80%", textAlign: "center", opacity: 0.5, marginTop: 25 }}>
                                                ** price(s) displayed include Insurance Premium Tax at 12% & any applicable fees
                                          </p>
                                    </Col></Row>
                        </Col>
                  </Row>
            </div>
      )
}

