import React, { useState, useEffect } from 'react'
import { Space, Row, Col } from 'antd'
import styles from './MainHeader.module.css'
import Logo from "../../../ui/Logo/index"
import { FiUser as UserIcon, FiMenu as MenuIcon } from "react-icons/fi"

export function MainHeader() {
      const [isScrolled, setIsScrolled] = useState(false)

      useEffect(() => {
            const handleScroll = () => {
                  if (window.scrollY > 100) {
                        setIsScrolled(true)
                  } else {
                        setIsScrolled(false)
                  }
            }

            window.addEventListener('scroll', handleScroll)
            return () => {
                  window.removeEventListener('scroll', handleScroll)
            }
      }, [])

      return (
            <div className={`${styles.MainHeader} ${isScrolled ? styles.solid : styles.transparent}`}>
                  <Row justify="center">
                        <Col span={22}>
                              <Row align="middle">
                                    <Col xs={12} sm={12}>
                                          <Logo />
                                    </Col>
                                    <Col xs={12} sm={12} style={{ textAlign: "right" }}>
                                          <Space size="large">
                                                <UserIcon style={{ fontSize: 28 }} />
                                                <MenuIcon style={{ fontSize: 28 }} />
                                          </Space>
                                    </Col>
                              </Row>
                        </Col>
                  </Row>
            </div>
      )
}
