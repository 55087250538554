import { FaCircleCheck as IncludeIcon, FaCircleXmark as ExcludeIcon } from "react-icons/fa6"
import { FaInfo as InfoIcon } from "react-icons/fa"
import { Tooltip } from "antd"
import styles from "./PricingTable.module.css"

export const tableConfig = [
      {
            title: 'COVERED',
            data: [
                  { key: 'a1', name: 'Tools', covered: true, tooltip: "Covers tools necessary for work" },
                  { key: 'a2', name: 'Materials', covered: true, tooltip: "Includes materials used in work" },
                  { key: 'a3', name: 'Stock', covered: true, tooltip: "Covers stock used for business" },
                  { key: 'a4', name: '1x Laptop / Tablet', covered: true, tooltip: "Covers one laptop or tablet" },
            ],
      },
      {
            title: 'EXCLUDED',
            data: [
                  { key: 'b1', name: 'Wear & tear', covered: false, tooltip: "Excludes normal wear and tear" },
                  { key: 'b2', name: 'Manufacturing faults', covered: false, tooltip: "Does not cover manufacturing faults" },
                  { key: 'b3', name: 'Phones', covered: false, tooltip: "Phones are excluded from coverage" },
                  { key: 'b4', name: 'Items unattended longer than 24hrs', covered: false, tooltip: "Items left unattended for over 24 hours are not covered" },
            ],
      },
      {
            title: 'EVENTS',
            data: [
                  { key: 'c1', name: 'Theft', covered: true, tooltip: "Covers theft under specific conditions" },
                  { key: 'c2', name: 'Fire', covered: true, tooltip: "Covers fire damage" },
                  { key: 'c3', name: 'Eaten', covered: false, tooltip: "Covers fire damage" },
            ],
      },
      {
            title: 'LOCATIONS',
            data: [
                  { key: 'd1', name: 'Home', covered: true, tooltip: "Coverage includes home" },
                  { key: 'd2', name: 'Work', covered: true, tooltip: "Coverage includes workplace" },
                  { key: 'd3', name: 'Pub', covered: false, tooltip: "Placeholder" },
            ],
      }
]

export const generateColumns = (tooltipHandler, visibleTooltipKey) => [
      {
            title: '', // Remove the 'Item' title
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                  <span>
                        {text}
                        <Tooltip
                              title={record.tooltip}
                              open={visibleTooltipKey === record.key}
                              onOpenChange={(visible) => {
                                    if (visible) {
                                          tooltipHandler(record.tooltip, record.key)
                                    } else {
                                          tooltipHandler("", null) // Close the tooltip
                                    }
                              }}
                        >
                              <InfoIcon
                                    style={{
                                          marginLeft: 10,
                                          color: "rgba(247, 184, 41, 0.5)",
                                          verticalAlign: -1,
                                          cursor: "pointer",
                                    }}
                              />
                        </Tooltip>
                  </span>
            ),
      },
      {
            title: '',
            key: 'covered',
            dataIndex: 'covered',
            width: 100,
            align: 'right',
            render: (item) =>
                  item ? (
                        <IncludeIcon className={`${styles.PricingTableIcon} ${styles.Green}`} />
                  ) : (
                        <ExcludeIcon className={`${styles.PricingTableIcon} ${styles.Red}`} />
                  ),
      }
]
