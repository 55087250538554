import { useNavigate } from "react-router-dom"

export default function Logo({ height = 20, color = 'rgba(255,255,255,1)' }) {

      const navigate = useNavigate()

      return (

            <div className="clickable" onClick={() => navigate("/")}>
                  <svg height={height} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 269.4 37.3">
                        <g>
                              <g id="Layer_1">
                                    <g>
                                          <g>
                                                <path style={{ fill: color }} d="M63.8,5.5v9.6h10.8v3.4h-10.8v16.1h-3.6V2.1h14.8v3.4h-11.1Z" />
                                                <path style={{ fill: color }} d="M93.1,34.6h-3.6V2.1h3.6v32.4Z" />
                                                <path style={{ fill: color }} d="M111.9,34.6h-3.6V2.1h4.7c6.2,0,11.9,1.6,11.9,9s-3.1,8.5-8.1,9l10.3,14.5h-4.4l-9.8-14.2h-.9v14.2ZM111.9,17.1h1.1c4.1,0,8.2-.8,8.2-5.8s-3.9-5.9-8.3-5.9h-1.1v11.7Z" />
                                                <path style={{ fill: color }} d="M154.9,8.5c-1.3-2.2-2.8-3.5-5.6-3.5s-5.4,2.1-5.4,5,2.9,4.1,5.1,5.1l2.2.9c4.2,1.9,7.8,4,7.8,9.1s-4.6,10-10.2,10-9.1-3.4-10.1-8.4l3.5-1c.5,3.3,3,6,6.5,6s6.7-2.7,6.7-6.3-3-5.1-6-6.5l-2-.9c-3.8-1.7-7.1-3.7-7.1-8.3s4.3-8.3,9.1-8.3,6.7,1.9,8.4,5l-2.9,1.9Z" />
                                                <path style={{ fill: color }} d="M180.7,34.6h-3.6V5.5h-7.8v-3.4h19.4v3.4h-7.9v29.1Z" />
                                                <path style={{ fill: color }} d="M204.5,5.5v9.6h10.8v3.4h-10.8v16.1h-3.6V2.1h14.8v3.4h-11.1Z" />
                                                <path style={{ fill: color }} d="M233.8,34.6h-3.6V2.1h3.6v32.4Z" />
                                                <path style={{ fill: color }} d="M247.1,2.1h4.1l6.6,12.2,6.6-12.2h4.1l-8.8,15.7,9.6,16.8h-4.1l-7.4-13.3-7.4,13.3h-4.1l9.6-16.8-8.8-15.7Z" />
                                          </g>
                                          <g>
                                                <path style={{ fill: color }} d="M15.9,4.2V0S9-.2.3,5.5c-.3,5.1-3.1,27.5,16.2,31.8-4.3-2.3-12-8.3-12.3-18.1,1-.6,5.8-3.9,11.7-4.3v-4.7s-6.7.3-12,3.6c-.1-1.5,0-4.3.1-5.7,1.5-.9,4.7-3.1,11.8-4Z" />
                                                <path style={{ fill: color }} d="M26.3,4.2V0s6.9-.2,15.6,5.5c.3,5.1,3.1,27.5-16.2,31.8,4.3-2.3,12-8.3,12.3-18.1-1-.6-5.8-3.9-11.7-4.3v-4.7s6.7.3,12,3.6c.1-1.5,0-4.3-.1-5.7-1.5-.9-4.7-3.1-11.8-4Z" />
                                          </g>
                                    </g>
                              </g>
                        </g>
                  </svg>
            </div>

      )

}
