import { useState, useContext, useEffect } from "react"
import { formatCurrency, delay } from "../../../../_utils/helper"
import { Row, Col, Slider, Switch } from "antd"
import { MyContext } from "../../../../App"
import styles from "./UserSelection.module.css"
import Wave from "../../../ui/Wave"

export function UserSelection() {

      const { AppData, setAppData, UserData, setUserData } = useContext(MyContext)
      const [cover, setCover] = useState(UserData?.policy?.price?.cover)
      const [paymentType, setPaymentType] = useState(UserData?.policy?.payment?.type)

      const updateQuote = async () => {

            setAppData({ loading: true })

            await delay(1000)

            const price = AppData.rating.bands.find(band => band.cover === cover)

            setUserData({
                  policy: {
                        price,
                        payment: {
                              type: paymentType
                        }
                  }
            })

            setAppData({ loading: false })

      }

      useEffect(() => {
            updateQuote()
            // eslint-disable-next-line
      }, [cover, paymentType])

      const selectValue = (value) => {
            setCover(value)
      }

      const selectPayment = (e) => {
            const tenure = e ? "Y" : "M"
            setPaymentType(tenure)
      }

      return (

            <>
                  <div style={{ marginTop: 45 }}>

                        <Row align="middle">
                              <Col>
                                    <div className={styles.tenureLabel} style={paymentType === "M" ? { opacity: 1 } : { opacity: 0.5 }}>
                                          MONTHLY
                                    </div>
                              </Col>
                              <Col>
                                    <Switch
                                          size="small"
                                          defaultValue={paymentType === "M" ? false : true}
                                          onChange={selectPayment}
                                          style={{ verticalAlign: -2, margin: "0 15px 0 15px" }}
                                    />
                              </Col>
                              <Col>
                                    <div className={styles.tenureLabel} style={paymentType === "Y" ? { opacity: 1 } : { opacity: 0.5 }}>
                                          YEARLY
                                    </div>
                              </Col>
                        </Row>

                  </div>

                  <div style={{ marginTop: 40, marginLeft: -5 }}>
                        <Row>
                              <Col xs={22} sm={8}>
                                    <Slider
                                          defaultValue={cover}
                                          min={1000}
                                          max={10000}
                                          step={1000}
                                          onChangeComplete={selectValue}
                                          tooltip={{
                                                open: true,
                                                placement: 'bottom',
                                                autoAdjustOverflow: false,
                                                formatter: formatCurrency
                                          }}
                                    />
                              </Col>
                        </Row>
                  </div>
            </>
      )
}

