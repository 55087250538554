import { Row, Col, Button } from "antd"
import { FaRegUserCircle as UserIcon } from "react-icons/fa"
import { FaBuildingShield as BusinessIcon } from "react-icons/fa6"
import { FaShieldAlt as CoverIcon } from "react-icons/fa"
import { FaMoneyCheck as BillingIcon } from "react-icons/fa"
import styles from "./Summary.module.css"

export function Summary({ customBottom = "" }) {

      const SummaryGridProps = { xs: { span: 24 }, sm: { span: 6 } }
      const ServiceGridProps = { xs: { span: 22 }, sm: { span: 6 } }

      return (

            <div className={`section section-dark ${customBottom}`}>

                  <Row justify="center">

                        <Col xs={22} sm={18}>

                              <Row >

                                    <Col className={styles.SummaryGridWrapper} {...SummaryGridProps}>

                                          <BusinessIcon className={styles.SummaryGridIcon} />
                                          <p className={styles.SummaryGridTitle}>Voli Limited</p>
                                          <p className={styles.SummaryGridValues}>
                                                Type: Limited Company<br />
                                                Established: 2009<br />
                                                Annual Revenue: £125-150k
                                          </p>
                                          <Button type="link">EDIT</Button>



                                    </Col>

                                    <Col className={styles.SummaryGridWrapper} {...SummaryGridProps}>

                                          <UserIcon className={styles.SummaryGridIcon} />
                                          <p className={styles.SummaryGridTitle}>Oliver Kingston</p>
                                          <p className={styles.SummaryGridValues}>
                                                Date of birth: 15/07/1981<br />
                                                Telephone: +44 7917 670401<br />
                                                Email: ojkingston@gmail.com
                                          </p>
                                          <Button type="link">EDIT</Button>

                                    </Col>

                                    <Col className={styles.SummaryGridWrapper} {...SummaryGridProps}>

                                          <CoverIcon className={styles.SummaryGridIcon} />
                                          <p className={styles.SummaryGridTitle}>Tools in transit</p>
                                          <p className={styles.SummaryGridValues}>
                                                Plan: Essentials<br />
                                                Cover limit: £3,000 x 1 vehicle<br />
                                                Excess: £100
                                          </p>
                                          <Button type="link">EDIT</Button>

                                    </Col>

                                    <Col className={styles.SummaryGridWrapper} {...SummaryGridProps}>

                                          <BillingIcon className={styles.SummaryGridIcon} />
                                          <p className={styles.SummaryGridTitle}>Billing: Debit Card</p>
                                          <p className={styles.SummaryGridValues}>
                                                Net Premium: £69.64<br />
                                                Insurance Premium Tax: £8.36<br />
                                                Total: £78.00
                                          </p>
                                          <Button type="link">EDIT</Button>

                                    </Col>

                              </Row>

                              <Row justify="center" style={{ marginTop: 75, marginBottom: 50 }}>
                                    <Col xs={20} sm={24}>
                                          <div className="alert">
                                                We'll evaluate your needs with questions and provide cover that matches them, confirmed in a Demands and Needs statement.
                                                If no policy meets your needs, we'll specify which requirements are unmet.
                                                We won't offer advice on the products, so you must judge their suitability yourself.
                                          </div>
                                    </Col>
                              </Row>

                              <div className={styles.ServiceGridWrapper}>

                                    <Row justify="center" gutter={64}>

                                          <Col {...ServiceGridProps} >
                                                <div>
                                                      <h2>Demands & needs</h2>
                                                      <p className={styles.SummaryGridValues}>
                                                            Your requested Tools in Transit risk details and sums insured in the above cover summary reflect your demands and needs as a carpenter.
                                                      </p>
                                                </div>
                                          </Col>

                                          <Col {...ServiceGridProps}>
                                                <div>
                                                      <h2>Our Service</h2>
                                                      <p className={styles.SummaryGridValues}>
                                                            We act as your Agent, ensuring the policy aligns with your demands and needs and we only use a single insurer, Royal & Sun Alliance Insurance Ltd.
                                                      </p>
                                                </div>
                                          </Col>

                                          <Col {...ServiceGridProps}>
                                                <div>
                                                      <h2>Related Cover</h2>
                                                      <p className={styles.SummaryGridValues}>
                                                            You may need additional insurance in your line of business, like Commercial Vehicle and Liability insurance, which you haven't requested from us today.
                                                      </p>
                                                </div>
                                          </Col>

                                    </Row>

                              </div>

                        </Col>
                  </Row>
            </div>

      )

}