import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { S2Form as Form } from "./Form"
import { UnderwrittenBy } from "../../common/sections/UnderwrittenBy"
import { Footer } from "../../common/Footer"

export default function Setup2() {

    return (

        <>

            <Header />

            <Hero
                backgroundImage="carpenter"
                mainHeader="Statement of fact"
                content="Answer the following truthfully as the responses form a contract between us."
            />

            <Form />

            <UnderwrittenBy />

            <Footer />

        </>

    )
}