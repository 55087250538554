import { Row, Col } from "antd";
import styles from "./Hero.module.css";
import Wave from "../../../ui/Wave";
import { useContext } from "react"
import { MyContext } from "../../../../App"

export function Hero({ backgroundImage = "carpenter", upperHeader = null, mainHeader = null, content = null, action = null, desktopContent = { span: 20, offset: 2 } }) {

      const { AppData, UserData } = useContext(MyContext)

      const heroStyle = {
            backgroundImage: `url("https://${AppData.endpoints.assets}.firstfix.co/images/heros/${backgroundImage}-${UserData?.device?.type !== "large" ? '575' : '1920'}.jpg")`,

      };

      return (
            <>
                  <div className={styles.heroContainer} style={heroStyle}>
                        <Row justify="center">
                              <Col span={22}>
                                    <Row>
                                          <Col xs={24} sm={desktopContent}>
                                                <div className={styles.heroContent}>
                                                      {upperHeader && <h2 style={{ marginBottom: -10 }}>{upperHeader}</h2>}
                                                      {mainHeader && <h1>{mainHeader}</h1>}
                                                      {content && <p style={{ marginTop: -30, opacity: 1, fontSize: 20, color: "var(--yellow)" }}>{content}</p>}
                                                      {action && action}
                                                </div>
                                          </Col>
                                    </Row>
                              </Col>
                        </Row>
                        <Wave />
                  </div>
            </>
      );
}
