import { Row, Col } from "antd"
import { useLocation } from "react-router-dom"
import { Footer } from "../../common/Footer"
import { Back } from "../../ui/Back"
import { UnderwrittenBy } from "../../common/sections/UnderwrittenBy"
import Logo from "../../ui/Logo"

const Error = () => {

      const location = useLocation()
      const path = location.pathname // Get the current path


      return (

            <>


                  <Row justify="center">

                        <Col xs={22} md={18}>

                              <Row justify="start" style={{ marginBottom: 300 }}>

                                    <Col span={12}>

                                          <Back align="left" style={{ marginTop: 100, marginBottom: 50 }} />

                                          <Logo height={30} color="rgba(0,0,0,1)" />

                                          <h1 style={{ marginTop: 20, fontSize: 44, marginBottom: 10 }}>Whoops, page not found</h1>
                                          <p style={{ fontSize: 18, marginTop: -10, marginBottom: 25, opacity: 0.5 }}>
                                                The requested URL {path} was not found on this server.
                                          </p>
                                          <p style={{ fontSize: 18, marginTop: -15, opacity: 0.5 }}>
                                                Go to <a style={{ fontSize: 18 }} href="/">firstfix.co</a>
                                          </p>

                                    </Col>

                              </Row>

                        </Col>

                  </Row>

                  <UnderwrittenBy style={{ marginTop: 0 }} />

                  <Footer />

            </>

      )
}

export default Error