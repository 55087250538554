import { Button } from "antd"
import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { UnderwrittenBy } from "../../common/sections/UnderwrittenBy"
import { WorksWith } from "../../common/sections/WorksWith"
import { Reviews } from "../../common/sections/Reviews"
import { Footer } from "../../common/Footer"
import { useNavigate } from "react-router-dom"

export default function Home() {

      const navigate = useNavigate()

      return (

            <>

                  <Header />

                  <Hero
                        desktopContent={{ span: 8, offset: 2 }}
                        backgroundImage="carpenter"
                        upperHeader="TOOL INSURANCE"
                        mainHeader="Protect your kit with Firstfix"
                        action={<Button type="primary" size="large" onClick={() => navigate("/quote")}>Start Quote</Button>}

                  />

                  <UnderwrittenBy />

                  <WorksWith />

                  <Reviews />

                  <Footer />


            </>

      )
}