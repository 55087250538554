import React, { useState } from "react"
import { AutoComplete, Input, Spin, Form, Button } from "antd"
import { lookupCompany } from "./helper"
import dayjs from "dayjs"
import styles from "./CompaniesHouse.module.css"

export function CompaniesHouse() {
      const [ options, setOptions ] = useState([])
      const [ loading, setLoading ] = useState(false)
      const [ selectedCompany, setSelectedCompany ] = useState(null)
      const [ inputValue, setInputValue ] = useState("") // Manage input value

      const handleSearch = async (value) => {
            setInputValue(value) // Update the input value state
            if (value.length >= 4) {
                  setLoading(true)
                  const companies = await lookupCompany(value)
                  const formattedOptions = companies.map((company) => ({
                        value: company.name,
                        label: (
                              <div>
                                    <strong>{company.name}</strong>
                                    <p style={{ margin: 0 }}>
                                          Incorporated in {dayjs(company.incorporated).format("YYYY")}
                                    </p>
                                    <p className="hideMobile" style={{ margin: 0, opacity: 0.5 }}>
                                          Registered address: {company.address}
                                    </p>
                              </div>
                        ),
                  }))
                  setOptions(formattedOptions)
                  setLoading(false)
            } else {
                  setOptions([])
            }
      }

      const handleSelect = (value) => {
            const company = options.find((option) => option.value === value)
            setSelectedCompany(company)
            setInputValue(value) // Set the input value to the selected company
      }

      const handleChangeCompany = () => {
            setSelectedCompany(null)
            setInputValue("") // Reset the input value
            setOptions([]) // Clear the options
      }

      return (
            <div id={styles.chWrapper}>
                  {selectedCompany ? (
                        <div id="addressSelectedWrapper">
                              <div style={{ color: "white", marginBottom: "10px" }}>
                                    <p>{selectedCompany.label.props.children[ 0 ]}</p>
                                    <p style={{ marginTop: -15, opacity: 0.5 }}>
                                          {selectedCompany.label.props.children[ 1 ].props.children}
                                    </p>
                                    <p style={{ marginTop: -15, opacity: 0.5 }}>
                                          {selectedCompany.label.props.children[ 2 ].props.children}
                                    </p>
                              </div>
                              <Button type="default" size="small" onClick={handleChangeCompany}>
                                    Change Company
                              </Button>
                        </div>
                  ) : (
                        <div id={styles.chWrapper}>
                              <Form.Item
                                    required
                                    name="ch_lookup"
                                    label={
                                          <div className={`${styles.CompaniesHouseLogo}`}>
                                                <img
                                                      className={`${styles.CompaniesHouseLogoInverted}`}
                                                      src="https://assets.firstfix.co/images/other-logos/companies-house.png"
                                                      alt="Companies House Logo"
                                                />
                                          </div>
                                    }
                              >
                                    <AutoComplete
                                          value={inputValue} // Bind the input value to the state
                                          options={options}
                                          onSearch={handleSearch}
                                          onSelect={handleSelect}
                                          onChange={setInputValue} // Ensure the input value updates correctly
                                          notFoundContent={loading ? <Spin size="small" /> : null}
                                    >
                                          <Input placeholder="Start typing your company name..." />
                                    </AutoComplete>
                              </Form.Item>
                        </div>
                  )}
            </div>
      )
}
