import { useContext } from "react"
import { MyContext } from "../../../../App"
import { Row, Col, Button, Form, Space, Radio, Divider } from "antd"
import { useNavigate } from "react-router-dom"
import styles from "./Form.module.css"

export function S2Form() {

    const { setUserData } = useContext(MyContext)

    const [form] = Form.useForm()

    const navigate = useNavigate()

    const onFinish = (data) => {
        console.log('Step Two Complete')
        console.log(data)
        setUserData({
            policy: {
                user: {
                    sof: {
                        foo: "bar"
                    }
                }
            }
        })
        navigate("/review")
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onRadioChange = (e, key) => {
        const value = e.target.value
        console.log(`${key} : ${value}`)
    }

    // const colPropsA = { xs: 0, sm: 1 };
    const qColProps = { xs: 24, sm: { span: 19 } };
    const aColProps = { xs: 24, sm: { span: 2, offset: 3 } };

    return (

        <div className={`section section-dark`} style={{ paddingTop: 50 }}>
            <Row justify="center">
                <Col xs={22} sm={18}>
                    <Form
                        id={styles.S2Form}
                        form={form}
                        name="S2Form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                    >

                        <h2>HAVE YOU :</h2>

                        <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>

                            <Col {...qColProps}>
                                Had any claims for, or have there been any incidents of, lost or damaged tools in the past 3 years?
                            </Col>

                            <Col {...aColProps}>
                                <Form.Item
                                    name="claims">
                                    <Radio.Group className={styles.RadioGroupWrapper} onChange={(e) => onRadioChange(e, "claims")}>
                                        <Radio.Button value="1">Yes</Radio.Button>
                                        <Radio.Button value="0">No</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider />

                        <h2>HAVE YOU OR ANY OF YOUR FELLOW DIRECTORS OR PARTNERS, AS INDIVIDUALS OR IN CONNECTION WITH ANY BUSINESS, BEEN:</h2>

                        <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>

                            <Col {...qColProps}>
                                Charged or convicted in the UK with a criminal offence other than a motoring conviction
                            </Col>

                            <Col {...aColProps}>
                                <Form.Item
                                    name="conviction">
                                    <Radio.Group className={styles.RadioGroupWrapper} onChange={(e) => onRadioChange(e, "conviction")}>
                                        <Radio.Button value="1">Yes</Radio.Button>
                                        <Radio.Button value="0">No</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>

                            <Col {...qColProps}>
                                Declined or refused insurance cover or had cover cancelled or been subject to special terms in respect of any cover that you have asked us to insure under this policy?
                            </Col>

                            <Col {...aColProps}>
                                <Form.Item
                                    name="cancelled">
                                    <Radio.Group className={styles.RadioGroupWrapper} onChange={(e) => onRadioChange(e, "cancelled")}>
                                        <Radio.Button value="1">Yes</Radio.Button>
                                        <Radio.Button value="0">No</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>

                            <Col {...qColProps}>
                                Declared bankrupt, subject to bankruptcy proceedings or to any voluntary or mandatory insolvency or winding up proceedings either as individuals or in connection with any other business?
                            </Col>

                            <Col {...aColProps}>
                                <Form.Item
                                    name="bankrupt">
                                    <Radio.Group className={styles.RadioGroupWrapper} onChange={(e) => onRadioChange(e, "bankrupt")}>
                                        <Radio.Button value="1">Yes</Radio.Button>
                                        <Radio.Button value="0">No</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>

                            <Col {...qColProps}>
                                Disqualified under the Company Directors Disqualification Act 1986 or any subsequent legislation?
                            </Col>

                            <Col {...aColProps}>
                                <Form.Item
                                    name="disqualified">
                                    <Radio.Group className={styles.RadioGroupWrapper} onChange={(e) => onRadioChange(e, "disqualified")}>
                                        <Radio.Button value="1">Yes</Radio.Button>
                                        <Radio.Button value="0">No</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Space>
                            <Button onClick={() => navigate(-1)} style={{ marginBottom: 125 }} type="default" htmlType="submit" size="large">
                                Back
                            </Button>
                            <Button style={{ marginBottom: 125 }} type="primary" htmlType="submit" size="large">
                                Next
                            </Button>
                        </Space>
                    </Form>
                </Col>
            </Row>
        </div>

    )

}