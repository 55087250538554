import { Row, Col, Form, Radio, Select, Button, message } from "antd"

export function AboutVehicleEquipment({ form }) {


      const sixPlus = () => {
            message.warning("Max 5 vehicles online. Please call us.")
      }

      return (
            <div className="darkFormSection">

                  <Row>
                        <Col>
                              <h2>YOUR VEHICLE(S) AND EQUIPMENT</h2>
                        </Col>
                  </Row>

                  <Row>
                        <Col xs={24} sm={10}>

                              <Form.Item
                                    required
                                    name="vehicles"
                                    label="Number of vehicles"
                              >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <Radio.Group style={{ flex: 1 }} size="small">
                                                <Radio.Button value="1">1</Radio.Button>
                                                <Radio.Button value="2">2</Radio.Button>
                                                <Radio.Button value="3">3</Radio.Button>
                                                <Radio.Button value="4">4</Radio.Button>
                                                <Radio.Button value="5">5</Radio.Button>
                                          </Radio.Group>

                                          <Button
                                                type="default"
                                                size="small"
                                                onClick={() => { sixPlus() }}
                                                style={{ fontSize: 12, marginLeft: '8px', opacity: 0.7 }}
                                          >
                                                6+
                                          </Button>
                                    </div>
                              </Form.Item>

                        </Col>

                  </Row>

                  <Row gutter={32} align="bottom">
                        <Col xs={24} sm={8}>
                              <Form.Item
                                    required
                                    name="vehicle_security"
                                    label="Vehicle Security"
                              >
                                    <Select
                                          options={[
                                                {
                                                      value: "HSL",
                                                      label: 'High security locks',
                                                },
                                                {
                                                      value: "HSLAA",
                                                      label: 'High security locks & aftermarket alarm',
                                                },
                                                {
                                                      value: "ML",
                                                      label: 'Manufacturers locks only',
                                                },
                                                {
                                                      value: "MLA",
                                                      label: 'Manufacturers locks & alarm',
                                                },
                                                {
                                                      value: "MLIA",
                                                      label: 'Manufacturers locks, immobiliser & alarm',
                                                },
                                                {
                                                      value: "MLAA",
                                                      label: 'Manufacturers locks & aftermarket alarm',
                                                }
                                          ]}
                                    />
                              </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                              <Form.Item
                                    required
                                    name="equipment_marked"
                                    label="Security marked equipment"
                              >
                                    <Select
                                          options={[
                                                {
                                                      value: 1,
                                                      label: 'Yes',
                                                },
                                                {
                                                      value: 0,
                                                      label: 'No',
                                                }
                                          ]}
                                    />
                              </Form.Item>
                        </Col>
                  </Row>
            </div>
      )
}
