import dayjs from "dayjs"

export const currentYear = dayjs().year()

export const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
]

export const startedYears = Array.from({ length: 60 }, (_, i) => 2024 - i)

export const getDaySuffix = (day) => {
      if (day > 3 && day < 21) return 'th' // Because 11th, 12th, 13th, etc.
      switch (day % 10) {
            case 1: return 'st'
            case 2: return 'nd'
            case 3: return 'rd'
            default: return 'th'
      }
}

export const dateOptions = Array.from({ length: 31 }, (_, i) => {
      const num = i + 1
      const dayWithLeadingZero = num < 10 ? `0${num}` : num.toString()
      return {
            value: num,
            label: `${dayWithLeadingZero}${getDaySuffix(num)}`,
      }
})

export const monthOptions = Array.from({ length: 12 }, (_, i) => {
      const num = i + 1
      return {
            value: num,
            label: `${months[ i ]}`,
      }
})