import { Row, Col } from "antd"
import { GiSelfLove as LoveIcon } from "react-icons/gi"
import { LogoCarousel } from "../../../ui/LogoCarousel"

export function WorksWith() {

      return (

            <div className="section section-yellow angled-bottom-decline">
                  <Row justify="center">
                        <Col span={22}>
                              <Row align="middle">
                                    <Col xs={24} style={{ textAlign: "center" }}>
                                          <LoveIcon style={{ fontSize: 64 }} />
                                          <h2 style={{ marginBottom: -50, color: "var(--dark) !important" }}>Protect the brands you love</h2>
                                          <LogoCarousel itemNames={["dewalt", "makita", "festool", "hilti"]} />
                                    </Col>
                              </Row>
                        </Col>
                  </Row>
            </div>

      )

}