import { Row, Col, Form, Input, Select } from "antd"
import { currentYear, dateOptions, monthOptions } from "../helper"
import { useContext } from "react"
import { MyContext } from "../../../../../App"

export function AboutYou({ form }) {

      const { UserData, setUserData } = useContext(MyContext)

      return (

            <div className="darkFormSection">

                  <Row>
                        <Col>
                              <h2>ABOUT YOU</h2>
                        </Col>
                  </Row>

                  <Row gutter={32} align="bottom">
                        <Col xs={24} sm={5}>
                              <Form.Item
                                    required
                                    name="first_name"
                                    label="Full name"
                              >
                                    <Input placeholder="First name" />
                              </Form.Item>
                        </Col>
                        <Col xs={24} sm={6}>
                              <Form.Item
                                    required
                                    name="last_name"
                              >
                                    <Input placeholder="Last name" />
                              </Form.Item>
                        </Col>
                  </Row>

                  <Row gutter={UserData?.device?.type === "small" ? 8 : 32} align="bottom">
                        <Col xs={8} sm={4}>
                              <Form.Item
                                    required
                                    name="dob_d"
                                    label="Date of birth"
                              >
                                    <Select
                                          placeholder="Day"
                                          options={dateOptions}
                                    />
                              </Form.Item>
                        </Col>
                        <Col xs={8} sm={4}>
                              <Form.Item
                                    required
                                    name="dob_m"
                              >
                                    <Select
                                          placeholder="Month"
                                          options={monthOptions}
                                    />
                              </Form.Item>
                        </Col>
                        <Col xs={8} sm={4}>
                              <Form.Item
                                    required
                                    name="dob_y"
                              >
                                    <Select
                                          placeholder="Year"
                                          options={Array.from({ length: 61 }, (_, i) => currentYear - 18 - i).map(year => ({
                                                value: year,
                                                label: year.toString(),
                                          }))}
                                    />
                              </Form.Item>
                        </Col>
                  </Row>

                  <Row gutter={32}>
                        <Col xs={24} sm={8}>
                              <Form.Item
                                    required
                                    name="email"
                                    label="Email"
                              >
                                    <Input placeholder="Enter your email" />
                              </Form.Item>
                        </Col>
                        <Col xs={24} sm={8}>
                              <Form.Item
                                    required
                                    name="telephone"
                                    label="Mobile"
                              >
                                    <Input placeholder="Enter your mobile number" />
                              </Form.Item>
                        </Col>
                  </Row>
            </div>
      )
}
