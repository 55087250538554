import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { S1Form as Form } from "./Form"
import { UnderwrittenBy } from "../../common/sections/UnderwrittenBy"
import { Footer } from "../../common/Footer"

export default function Setup1() {

      return (

            <>

                  <Header />

                  <Hero
                        backgroundImage="carpenter"
                        mainHeader="Setup policy"
                        content="We'll use the information you provide to create your policy documents."
                  />

                  <Form />

                  <UnderwrittenBy />

                  <Footer />

            </>

      )
}