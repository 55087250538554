import { Row, Col } from "antd"
import { PiFilePdf as PdfIcon } from "react-icons/pi"
import styles from "./ExampleDocs.module.css"

export function ExampleDocs({ customBottom = "" }) {

      const DocsWrapperProps = { xs: { span: 22 }, sm: { span: 12 } }
      const DocsColumnProps = { xs: { span: 24 }, sm: { span: 12 } }
      const DocsItemSvgWidth = 50

      return (

            <div className={`section section-yellow ${customBottom}`}>

                  <Row justify="center">

                        <Col {...DocsWrapperProps}>

                              <Row gutter={32}>

                                    <Col {...DocsColumnProps}>

                                          <div className={styles.DocsItem}>
                                                <Row align="middle">
                                                      <Col flex={`${DocsItemSvgWidth}px`}><PdfIcon /></Col>
                                                      <Col flex="auto">POLICY SUMMARY</Col>
                                                </Row>
                                          </div>

                                          <div className={styles.DocsItem}>
                                                <Row align="middle">
                                                      <Col flex={`${DocsItemSvgWidth}px`}><PdfIcon /></Col>
                                                      <Col flex="auto">POLICY WORDING</Col>
                                                </Row>
                                          </div>

                                          <div className={styles.DocsItem}>
                                                <Row align="middle">
                                                      <Col flex={`${DocsItemSvgWidth}px`}><PdfIcon /></Col>
                                                      <Col flex="auto">EXAMPLE CERTIFICATE</Col>
                                                </Row>
                                          </div>

                                    </Col>

                                    <Col {...DocsColumnProps}>

                                          <div className={styles.DocsItem}>
                                                <Row align="middle">
                                                      <Col flex={`${DocsItemSvgWidth}px`}><PdfIcon /></Col>
                                                      <Col flex="auto">DEMANDS & NEEDS</Col>
                                                </Row>
                                          </div>

                                          <div className={styles.DocsItem}>
                                                <Row align="middle">
                                                      <Col flex={`${DocsItemSvgWidth}px`}><PdfIcon /></Col>
                                                      <Col flex="auto">SERVICE & REMUNERATION</Col>
                                                </Row>
                                          </div>

                                          <div className={styles.DocsItem}>
                                                <Row align="middle">
                                                      <Col flex={`${DocsItemSvgWidth}px`}><PdfIcon /></Col>
                                                      <Col flex="auto">TERMS OF BUSINESS</Col>
                                                </Row>
                                          </div>

                                    </Col>
                              </Row>
                        </Col>
                  </Row >
            </div >

      )

}