import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { Summary } from "./Summary"
import { ExampleDocs } from "./ExampleDocs"
import { TermsConditions } from "./TermsConditions"
import { UnderwrittenBy } from "../../common/sections/UnderwrittenBy"
import { Footer } from "../../common/Footer"

export default function Review() {

    return (

        <>

            <Header />

            <Hero
                backgroundImage="carpenter"
                mainHeader="Review your policy"
                content="Check everything carefully as this is the last step."
                action={<p style={{ color: "white", fontSize: 20 }}>Measure twice, cut once.</p>}
            />

            <Summary customBottom="angled-bottom-incline" />

            <ExampleDocs customBottom="angled-bottom-decline" />

            <TermsConditions />

            <UnderwrittenBy />

            <Footer />

        </>

    )
}