export function isHostAllowed() {
      const d = window.location.host
      if (d.includes("cloudfront") || d.includes("s3")) { return false } else { return true }
}

export async function delay(ms) {
      return await new Promise(resolve => setTimeout(resolve, ms))
}

export const formatCurrency = (value) => {
      const formattedValue = value.toFixed(2) // Ensures 2 decimal places
      return `£${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formattedValue)}`
}