import { Row, Col, Button } from "antd"
import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { Footer } from "../../common/Footer"
import { useNavigate } from "react-router-dom"

export default function Complete() {


    const navigate = useNavigate()

    return (

        <>

            <Header />

            <Hero
                backgroundImage="carpenter"
                mainHeader="Thank you"
                content="Viewing or changing your policy can all be completed online 24/7."
            />

            <div className={`section section-dark`}>
                <Row justify="center">
                    <Col span={18}>
                        <Button
                            type="default"
                            style={{ display: "block", marginTop: 50, marginBottom: 125 }}
                            size="large"
                            onClick={() => { window.location.href = "/" }}
                        >
                            Kill Session Data & Start Over
                        </Button>
                    </Col>
                </Row>
            </div>

            <Footer />

        </>

    )
}