import { NotificationBar } from "./NotificationBar"
import { MainHeader } from "./MainHeader"
import styles from './Header.module.css'


export function Header() {

      return (

            <div className={styles.Header}>

                  <NotificationBar />

                  <MainHeader />

            </div>
      )
}
