import { useContext } from "react"
import { MyContext } from "../../../../App"
import { Row, Col, Typography, Checkbox, Button, Space } from "antd"
import { useNavigate } from "react-router-dom"

const { Link } = Typography

export function TermsConditions({ customBottom = "" }) {

      const { UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      const startPayment = () => {
            setUserData({
                  policy: {
                        user: {
                              tandc: true
                        }
                  }
            })
            navigate("/pay")
      }

      return (

            <div className={`section section-dark ${customBottom}`}>
                  <Row justify="center">
                        <Col span={22}>
                              <Row justify="center" style={{ marginTop: 75 }}>

                                    <Col xs={24} md={16}>

                                          <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>IMPORTANT INFORMATION</p>
                                          <ul style={{ fontSize: 14, marginTop: 50, marginBottom: 50, marginLeft: -20 }}>
                                                <li style={{ marginBottom: 20 }}>You have taken reasonable care to answer all questions honestly and to the best of your
                                                      knowledge, and if you volunteer any other information, you must ensure that the information is not misleading. If any information that you have provided to us changes you must inform us of the change. If you
                                                      deliberately, recklessly or carelessly misrepresent any information in relation to this insurance then your policy may be cancelled without refund, or treated as if it never existed, or your claim
                                                      rejected or not fully paid.</li>
                                                <li style={{ marginBottom: 20 }}>You agree to receive your documents by email. If you would prefer to receive a paper copy please contact us.</li>
                                          </ul>
                                          <Checkbox>
                                                <span style={{ marginLeft: 5 }}>I have read, understood and agree with the above statements.</span>
                                          </Checkbox>
                                          <br />
                                          <Checkbox style={{ marginTop: 10 }}>
                                                <span style={{ marginLeft: 5 }}>I have reviewed the <Link href="URL_TO_POLICY_SUMMARY" target="_blank">Policy Summary</Link>,
                                                      <Link href="URL_TO_POLICY_WORDING" target="_blank"> Policy Wording</Link>, and
                                                      <Link href="URL_TO_TERMS_OF_BUSINESS" target="_blank"> Terms of Business</Link>.</span>
                                          </Checkbox>


                                          <div style={{ marginTop: 50, marginBottom: 125 }}>
                                                <Space >
                                                      <Button onClick={() => navigate(-1)} type="default" htmlType="submit" size="large">
                                                            Back
                                                      </Button>
                                                      <Button
                                                            type="primary"
                                                            size="large"
                                                            onClick={() => { startPayment() }}
                                                      >
                                                            Next
                                                      </Button>
                                                </Space>
                                          </div>

                                    </Col>

                              </Row>


                        </Col>
                  </Row>
            </div>

      )

}