import React from 'react'
import ReactDOM from 'react-dom/client'
import '../src/_assets/styles/global.css'
import '../src/_assets/styles/form.css'
import { App } from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
      <App />
)
