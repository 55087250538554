import { Row, Col } from "antd"
import styles from './LogoCarousel.module.css'

export function LogoCarousel({ itemNames }) {

      const totalColumns = 24
      const spanSize = Math.floor(totalColumns / itemNames.length)
      const totalSpanUsed = spanSize * itemNames.length
      const offset = Math.floor((totalColumns - totalSpanUsed) / 2) // Calculate offset to center the items

      return (

            <div id={styles.LogoCarousel}>

                  <Row>

                        {itemNames.map((name, index) => {

                              const isOffsetNeeded = index === 0 // Apply offset only to the first column

                              return (

                                    <Col
                                          className={styles.LogoCarouselCell}
                                          key={name}
                                          span={spanSize}
                                          offset={isOffsetNeeded ? offset : 0}
                                    >

                                          <img src={`https://assets.firstfix.co/images/other-logos/${name}.png`} alt={`${name} logo`} style={{ height: 30 }} />

                                    </Col>
                              )
                        })}

                  </Row>

            </div>

      )

}