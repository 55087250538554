import { useState, useEffect } from "react"
import { Row, Col, Form, Radio, Select, Button, Input } from "antd"
import { CompaniesHouse } from "./CompaniesHouse"
import { startedYears } from "../helper"

export function AboutBusiness({ form }) {

      const [ expanded, setExpanded ] = useState(false)
      const [ businessType, setBusinessType ] = useState(false)

      useEffect(() => {
            if (businessType) {
                  console.log('selectBusinessType()', businessType)
            }

      }, [ businessType ])

      return (
            <div className="darkFormSection">

                  <Row>
                        <Col>
                              <h2>YOUR BUSINESS</h2>
                        </Col>
                  </Row>

                  <Row>
                        <Col xs={24} sm={expanded ? 24 : 12}>

                              <Form.Item
                                    required
                                    name="type"
                                    label="Type of Business"
                              >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <Radio.Group style={{ flex: 1 }}>
                                                {expanded ? (
                                                      <>
                                                            <Radio.Button value="ST" onClick={() => setBusinessType("ST")}>Sole Trader</Radio.Button>
                                                            <Radio.Button value="PA" onClick={() => setBusinessType("PA")}>Partnership</Radio.Button>
                                                            <Radio.Button value="LP" onClick={() => setBusinessType("LP")}>Partnership (LLP)</Radio.Button>
                                                            <Radio.Button value="LC" onClick={() => setBusinessType("LC")}>Limited Company</Radio.Button>
                                                      </>
                                                ) : (
                                                      <>
                                                            <Radio.Button value="ST" onClick={() => setBusinessType("ST")}>Sole Trader</Radio.Button>
                                                            <Radio.Button value="LC" onClick={() => setBusinessType("LC")}>Limited Company</Radio.Button>
                                                      </>
                                                )}
                                          </Radio.Group>
                                    </div>
                                    {expanded
                                          ? <p style={{ color: "var(--white)", opacity: 0.5 }}>Want fewer options?
                                                <span
                                                      className="psuedo-link"
                                                      onClick={() => setExpanded(expanded => !expanded)}
                                                > Click here
                                                </span>
                                          </p>
                                          : <p style={{ color: "var(--white)", opacity: 0.5 }}>Trading in a partnership?
                                                <span
                                                      className="psuedo-link"
                                                      onClick={() => setExpanded(expanded => !expanded)}
                                                > Click here
                                                </span>
                                          </p>
                                    }
                              </Form.Item>
                        </Col>
                  </Row>

                  {
                        !businessType
                              ? null
                              :

                              <>

                                    {(businessType === "LC" || businessType === "LP") &&
                                          <CompaniesHouse form={form} />
                                    }


                                    {businessType === "PA" &&
                                          <Row gutter={32} align="bottom">
                                                <Col xs={24} sm={5}>
                                                      <Form.Item
                                                            required
                                                            name="partner_first_name"
                                                            label="Partner name"
                                                      >
                                                            <Input placeholder="First name" />
                                                      </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={6}>
                                                      <Form.Item
                                                            required
                                                            name="partner_last_name"
                                                      >
                                                            <Input placeholder="Last name" />
                                                      </Form.Item>
                                                </Col>
                                          </Row>
                                    }

                                    <Row gutter={32} align="bottom">
                                          {(businessType !== "LC" && businessType !== "LP") &&
                                                <Col xs={24} sm={8}>
                                                      <Form.Item
                                                            required
                                                            name="year"
                                                            label="Year Business Started"
                                                      >
                                                            <Select
                                                                  options={startedYears.map(year => ({
                                                                        value: year,
                                                                        label: year.toString(),
                                                                  }))}
                                                            />
                                                      </Form.Item>
                                                </Col>
                                          }
                                          <Col xs={24} sm={8}>
                                                <Form.Item
                                                      required
                                                      name="revenue"
                                                      label="Annual Revenue"
                                                >
                                                      <Select
                                                            options={[
                                                                  {
                                                                        value: "0-50",
                                                                        label: '£0 - 50k',
                                                                  },
                                                                  {
                                                                        value: "50-100",
                                                                        label: '£50k - 100k',
                                                                  },
                                                                  {
                                                                        value: "100-250",
                                                                        label: '£100k - 250k',
                                                                  },
                                                                  {
                                                                        value: "250-500",
                                                                        label: '£250k - 500k',
                                                                  },
                                                                  {
                                                                        value: "500-1000",
                                                                        label: '£500k - £1m',
                                                                  },
                                                                  {
                                                                        value: "1000+",
                                                                        label: '£1m+',
                                                                  }
                                                            ]}
                                                      />

                                                </Form.Item>


                                          </Col>
                                    </Row>

                              </>
                  }


            </div >
      )
}
