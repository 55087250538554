import { Header } from "../../common/Header"
import { Hero } from "../../common/sections/Hero"
import { UserSelection } from "./UserSelection"
import { PricingTable } from "./PricingTable"
import { WorksWith } from "../../common/sections/WorksWith"
import { UnderwrittenBy } from "../../common/sections/UnderwrittenBy"
import { Footer } from "../../common/Footer"

export default function Home() {

      return (

            <>

                  <Header />

                  <Hero
                        backgroundImage="carpenter"
                        mainHeader="Configure plan"
                        content="Select the value of tools and how you prefer to pay"
                        action={<UserSelection />}

                  />

                  <PricingTable />

                  <WorksWith />

                  <UnderwrittenBy />

                  <Footer />

            </>

      )
}