import { Row, Col } from "antd"

export function UnderwrittenBy({ customBottom = "" }) {

      return (

            <div className={`section section-dark ${customBottom}`}>
                  <Row justify="center">
                        <Col span={22}>
                              <Row align="middle">
                                    <Col xs={24} sm={{ span: 10, offset: 2 }}>
                                          <p className="lead" style={{ color: "var(--white)" }}>Royal & Sun Alliance Insurance Ltd is one of the world's oldest general insurance companies, offering products to help you manage risks and recover when things go wrong.</p>
                                    </Col>
                                    <Col xs={24} sm={{ span: 6, offset: 3 }}>
                                          <img width="200" src="https://assets.firstfix.co/images/other-logos/rsa.png" alt="RSA Logo" />
                                          <h2 style={{ fontSize: 12 }}>UNDERWRITTEN BY</h2>
                                    </Col>
                              </Row>
                        </Col>
                  </Row>
            </div>

      )

}