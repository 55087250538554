import { message } from "antd"

export const lookupCompany = async (value) => {
      try {
            const response = await fetch("https://stage-api.voli.uk/company/lookup", {
                  method: "POST",
                  headers: {
                        "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ value: value.toUpperCase() }),
            })

            const jsonResponse = await response.json()

            if (jsonResponse.status === "ERROR") {
                  // message.error(jsonResponse.result)
                  return []
            }

            if (jsonResponse.status === "OK" && Array.isArray(jsonResponse.result)) {
                  return jsonResponse.result
            } else {
                  // message.error("No company match found")
                  return []
            }
      } catch (error) {
            message.error("Error finding company name: " + error.message)
            return []
      }
}
