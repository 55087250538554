import { useContext } from "react"
import { Row, Col } from "antd"
import Divider from '../../ui/Divider'
import Logo from '../../ui/Logo'
import styles from './Footer.module.css'
import { MyContext } from "../../../App"


export function Footer() {

      const { UserData } = useContext(MyContext)

      const vw = UserData?.device?.viewport?.width || false

      return (

            <div id={styles.Footer}>

                  <Row gutter={16} justify="center" align="top">

                        <Col xs={23} md={20}>

                              <Row>

                                    <Col xs={24} md={4} style={vw <= 768 ? { marginBottom: 50 } : {}}>

                                          <Logo color="rgba(255,255,255,0.5)" height="20" />

                                    </Col>

                                    <Col xs={24} md={8} style={{ paddingLeft: 25, borderLeft: '1px solid rgba(255,255,255,0.1)', marginBottom: vw <= 768 ? 50 : null }} >

                                          <div className={styles.footerNavLinks}>
                                                <a target="_blank" rel="noopener noreferrer" href="https://assets.firstfix.co/docs/policy-summary.pdf">
                                                      Policy Summary
                                                </a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://assets.firstfix.co/docs/policy-wording.pdf">
                                                      Policy Wording
                                                </a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://assets.firstfix.co/docs/ipid.pdf">
                                                      Insurance Product Information Document (IPID)
                                                </a>

                                                <Divider theme="dark" customStyle={{ width: "50%" }} />

                                                <a target="_blank" rel="noopener noreferrer" href="https://assets.firstfix.co/docs/toba.pdf">
                                                      Terms of business
                                                </a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://assets.firstfix.co/docs/privacy.pdf">
                                                      Privacy, Data & Cookies
                                                </a>
                                          </div>

                                    </Col>

                                    <Col xs={24} md={{ span: 10, offset: 2 }}>

                                          <div>
                                                © 2024 Voli Ltd. All rights reserved.
                                          </div>

                                          <Divider theme="dark" />

                                          <div>
                                                Firstfix is a trading name of Voli Limited. Voli Limited is authorised and regulated by the <a href="https://www.fca.org.uk/">Financial Conduct Authority</a> for General Insurance Distribution activities and as a Credit Broker. We do not charge any up-front fees for arranging credit. Reg No. 1015906.
                                          </div>

                                          <Divider theme="dark" />

                                          <div>Our services are covered by the <a href="https://www.financial-ombudsman.org.uk/">Financial Ombudsman Service</a>. If you cannot settle
                                                a complaint with us, eligible complainants may be entitled to refer it to the
                                                Financial Ombudsman Service for an independent assessment. The FOS Consumer
                                                Helpline is on 0800 023 4567 and their address is:
                                                Financial Ombudsman Service, Exchange Tower, London E14 9SR.</div>

                                          <Divider theme="dark" />

                                          <div>
                                                Registered in England No. 15554073 Registered Office: Suite 22 Trym Lodge, 1 Henbury Road, Westbury-On-Trym, Bristol, United Kingdom, BS9 3HQ
                                          </div>

                                          <Divider theme="dark" />

                                          <div>
                                                v1.0.1
                                          </div>

                                    </Col>

                              </Row>

                        </Col>

                  </Row >

            </div >

      )
}