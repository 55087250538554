import { Row, Col } from "antd"
import { VscQuote as QuoteIcon } from "react-icons/vsc"
import { FaStar as StarIcon } from "react-icons/fa"
import styles from './Reviews.module.css'
import Wave from "../../../ui/Wave"

export function Reviews() {

      return (

            <div className="section section-dark">
                  <Row justify="center" style={{ paddingBottom: 100 }}>
                        <Col span={18}>
                              <Row align="middle">
                                    <Col xs={0} sm={1}><QuoteIcon style={{ fontSize: 74, color: 'rgba(255,255,255,0.3)' }} /></Col>
                                    <Col xs={24} sm={{ span: 10, offset: 2 }}>
                                          <p className="lead" style={{ color: "var(--white)" }}>Very easy to navigate on the webpage and super easy to buy cover, no hundreds of questions, literally took me around 10 minutes.</p>
                                    </Col>
                                    <Col xs={24} sm={{ span: 6, offset: 3 }} style={{ textAlign: "center" }}>
                                          <img width="150" src="https://assets.firstfix.co/images/avatars/avatar.png" alt="avatar" style={{
                                                borderRadius: "50%", padding: 15, backgroundColor: "rgba(247, 184, 41,0.2)"
                                          }} />
                                          < h2 style={{ marginTop: 25, fontSize: 11, marginBottom: 25 }}>C BURGE ELECTRICAL</h2>
                                          <div className={styles.ReviewStars}><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></div>
                                    </Col>
                              </Row>
                        </Col>
                  </Row >
                  <Wave after="#000000" />
            </div >

      )
}