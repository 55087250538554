import { message } from "antd"
import { postcodeValidator } from "postcode-validator"

export const handlePostcodeValidation = async (rule, value, form, setAddresses, setDropdownVisible, setLoading) => {
      let upperCaseValue

      if (value) {
            // Force uppercase
            upperCaseValue = value.toUpperCase()
            form.setFieldsValue({ search: upperCaseValue })
      }

      if (value && value.length >= 6) {
            // Validate the postcode using postcode-validator
            const isValid = postcodeValidator(upperCaseValue, "UK")
            if (!isValid) {
                  return Promise.reject("")
            }

            // Set loading state to true before API call
            setLoading(true)

            // Send the valid postcode to the API
            try {
                  const response = await fetch("https://stage-api.voli.uk/postcode", {
                        method: "POST",
                        headers: {
                              "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ postcode: upperCaseValue }),
                  })

                  // Since the API always returns 200, we check the status in the JSON response
                  const jsonResponse = await response.json()

                  if (jsonResponse.status === "ERROR") {
                        // If the status is "ERROR", reject the promise with the error message
                        console.log('aa')
                        return Promise.reject(jsonResponse.result || "Failed to validate postcode")
                  }

                  // If the status is "OK", proceed with setting the addresses
                  if (Array.isArray(jsonResponse.result)) {
                        setAddresses(jsonResponse.result)
                        setDropdownVisible(true) // Show the dropdown immediately when results are received
                  } else {
                        setAddresses([]) // In case jsonResponse is not an array
                        setDropdownVisible(false)
                        return Promise.reject("No addresses found for this postcode")
                  }
            } catch (error) {
                  setAddresses([]) // Reset addresses on error
                  setDropdownVisible(false) // Hide the dropdown on error
                  return Promise.reject("Error sending postcode: " + error.message)
            } finally {
                  // Set loading state to false after API call is complete
                  setLoading(false)
            }

            return Promise.resolve()
      }

      return Promise.resolve()
}
