import { useContext } from 'react'
import { Row, Col } from 'antd'
import styles from './NotificationBar.module.css'
import { CheckoutSteps } from "./CheckoutSteps"
import { MyContext } from '../../../../App'


export function NotificationBar() {

      const { UserData } = useContext(MyContext)

      return (

            <div id={styles.NotificationBar}>

                  <Row justify="center">

                        <Col span={22}>

                              {UserData?.policy?.price?.essentials && UserData?.device?.type === "large"
                                    ? <CheckoutSteps />
                                    : <p>We can use this area for anything we want!</p>
                              }

                        </Col>

                  </Row>

            </div>
      )
}
